import Image from "next/image";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { HeroCharacter, HeroLandscape, HeroMonster } from "@/images";
// Move showcase items outside component to prevent recreation
const showcaseItems = [{
  title: "Elf Paladin",
  desc: "Create legendary characters",
  src: HeroCharacter
}, {
  title: "Legendary Beholder",
  desc: "Generate Monsters with ease",
  src: HeroMonster
}, {
  title: "Mirabar",
  desc: "Craft captivating landscapes in seconds",
  src: HeroLandscape
}];
export default function LandingHero() {
  const isMobile = useIsMobile();
  return <div className="dark bg-slate-950 text-slate-100" data-sentry-component="LandingHero" data-sentry-source-file="LandingHero.tsx">
            <main className="flex-1">
                {/* Hero Section - Critical for LCP */}
                <section className="relative pt-32 pb-24 overflow-hidden">
                    <div className="container mx-auto px-4">
                        <div className="max-w-4xl mx-auto text-center mb-16">
                            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
                                Instant AI Generated Art for Your RPGs
                            </h1>
                            <p className="text-lg md:text-xl text-slate-400 mb-8 max-w-2xl mx-auto">
                                Create stunning character art for your RPGs in
                                seconds. No prompting expertise required.
                            </p>
                            <div className="flex flex-col sm:flex-row justify-center gap-4 mb-12">
                                <Link href="/generate" prefetch={false} title="Get Started For Free" data-sentry-element="Link" data-sentry-source-file="LandingHero.tsx">
                                    <Button size="lg" className="!bg-emerald-700 hover:!bg-emerald-800 hover:scale-105 transition-all duration-200" data-sentry-element="Button" data-sentry-source-file="LandingHero.tsx">
                                        🔥 Get Started For Free
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        {/* Hero Images Grid - Critical for LCP */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
                            {showcaseItems.map((item, index) => <div key={index} className="group relative aspect-[3/4] overflow-hidden rounded-xl bg-slate-900 transform transition-transform hover:scale-105">
                                    <Image src={item.src} alt={item.title} fill loading={index === 0 || !isMobile ? "eager" : "lazy"} priority={index === 0} sizes={isMobile ? "100vw" : "33vw"} className="object-cover transition-transform duration-300 group-hover:scale-110" quality={90} placeholder="blur" blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx4eHRoaHSQtJSAyVC08MTY3LjIyOUFTRjo/Tj4yMkhiSk46NjVBWlFOTU5BQUFBQUH/2wBDAQoLCw8NDx0QEBBNTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTk5OTn/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k=" />
                                    {!isMobile && <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />}
                                    <div className="absolute bottom-0 left-0 right-0 p-4">
                                        <p className="text-white font-medium">
                                            {item.title}
                                        </p>
                                        <p className="text-sm text-slate-300">
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </section>
            </main>
        </div>;
}