// src/pages/index.tsx
import { Loader2 } from "lucide-react";
import dynamic from "next/dynamic";

// Import only the critical part of Landing (Hero section) without lazy loading
import LandingHero from "@/components/organisms/Landing/sections/LandingHero";

// Lazily load the rest of the Landing component
const LandingContent = dynamic(() => import("@/components/organisms/Landing/LandingContent"), {
  ssr: false,
  // Temporarily disable SSR for diagnostics
  loading: () => <div className="h-16 flex items-center justify-center">
                <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
            </div>
});
const Home = () => {
  return <div data-sentry-component="Home" data-sentry-source-file="index.tsx">
            {/* Critical Hero Section - Rendered immediately */}
            <LandingHero data-sentry-element="LandingHero" data-sentry-source-file="index.tsx" />
            <LandingContent data-sentry-element="LandingContent" data-sentry-source-file="index.tsx" />
        </div>;
};
export default Home;